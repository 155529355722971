import type { App } from 'vue'
import {
  Input,
  InputNumber,
  Layout,
  Select,
  SelectOption,
  Button,
  Tooltip,
  Popconfirm,
  Skeleton,
  Form,
  Row,
  Cascader,
  Radio,
  RadioGroup,
  Transfer,
  Tree,
  TreeSelect,
  Col,
  Divider,
  Spin,
  PageHeader,
  Table,
  Popover,
  Modal,
  Alert,
  Menu,
  Tabs,
  Pagination,
  Breadcrumb,
  Upload,
  Image,
  Progress,
  Badge,
  Checkbox,
  CheckboxGroup,
  Empty,
  DescriptionsItem,
  Descriptions,
  Switch,
  Tag,
  DatePicker,
  RangePicker,
  Dropdown,
  Drawer,
  Steps,
  Step,
  Card,
  Result,
  AutoComplete,
  Avatar,
  // Breadcrumb,
  Anchor,
  Affix,
  Timeline,
  BackTop,
} from 'ant-design-vue'

export function registerGlobAntdComp(app: App) {
  app
    .use(Input)
    .use(InputNumber)
    .use(Select)
    .use(SelectOption)
    .use(Button)
    .use(Layout)
    .use(Tooltip)
    .use(Popconfirm)
    .use(Skeleton)
    .use(Form)
    .use(Row)
    .use(Cascader)
    .use(Radio)
    .use(RadioGroup)
    .use(Transfer)
    .use(TreeSelect)
    .use(Tree)
    .use(Divider)
    .use(Col)
    .use(Spin)
    .use(PageHeader)
    .use(Table)
    .use(Popover)
    .use(Modal)
    .use(Alert)
    .use(Menu)
    .use(Tabs)
    .use(Pagination)
    .use(Breadcrumb)
    .use(Upload)
    .use(Image)
    .use(Progress)
    .use(Badge)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Select)
    .use(Empty)
    .use(Descriptions)
    .use(DescriptionsItem)
    .use(Switch)
    .use(Dropdown)
    .use(Tag)
    .use(DatePicker)
    .use(RangePicker)
    .use(DatePicker.RangePicker)
    .use(Drawer)
    .use(Steps)
    .use(Step)
    .use(Card)
    .use(Result)
    .use(AutoComplete)
    .use(Anchor)
    .use(Avatar)
    .use(Affix)
    .use(Timeline)
    .use(BackTop)
  // .use(Breadcrumb)
}
